import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactContent from "../components/content/contact"
import Nav from "../components/nav"
import Footer from "../components/footer"

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <Nav />
    <ContactContent />
    <Footer />
  </>
)

export default ContactPage
